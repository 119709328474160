import React from 'react';
import * as styles from "./styles.module.scss";
import bgCircle from "../img/bgCircle.png"
import {BlueLike, DarkLike} from "./TimeLikes";
import TimeLine from "./TimeLine";
import TimeCircle from "./TimeCircle";


const TimeMain = () => {
    return (
        <div className={styles.timeHeader_container}>
            <div className={styles.bg_circle}>
                <img src={bgCircle} alt=""/>
            </div>
            <div className={styles.blueLike_block}>
                <BlueLike/>
            </div>
            <div className={styles.darkLike_block}>
                <DarkLike/>
            </div>
            <div className={styles.timeLine_block}>
                <TimeLine/>
            </div>
            <div className={styles.timeCircle_block}>
                <TimeCircle/>
            </div>
        </div>
    );
};

export default TimeMain