import React, {useEffect} from 'react';
import Parallax from "parallax-js";
import line from "../img/line.png"
import dashed from "../../dashedImg/darkBlueBg.png";
import * as styles from "./styles.module.scss";


const TimeLine = () => {

    useEffect(() => {
        const line_scene = document.getElementById("line_scene");
        new Parallax(line_scene);
    });

    return (
        <div className={styles.line_container}>
            <svg width="409" height="386" viewBox="0 0 409 386" fill="none" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="mask">
                    <path
                        d="M77.9504 272.481C100.939 266.321 116.675 275.978 126.97 282.33C136.259 288.019 139.069 289.233 144.768 287.706C150.563 286.153 152.319 283.819 157.493 274.151C163.426 263.451 172.128 247.246 195.117 241.086C218.106 234.926 233.842 244.583 244.136 250.935C253.426 256.624 256.236 257.839 261.934 256.312C267.73 254.759 269.486 252.425 274.66 242.756C280.592 232.056 289.392 215.826 312.284 209.692C335.273 203.532 351.008 213.188 361.303 219.54C370.715 225.3 373.402 226.444 379.198 224.891C389.823 222.044 400.846 228.408 403.693 239.033C406.54 249.658 400.176 260.681 389.551 263.528C366.562 269.688 350.826 260.031 340.531 253.679C331.242 247.99 328.432 246.776 322.733 248.303C316.938 249.856 315.182 252.19 310.008 261.858C304.075 272.558 295.276 288.789 272.384 294.923C249.491 301.057 233.659 291.426 223.365 285.074C214.075 279.385 211.265 278.17 205.567 279.697C199.771 281.25 198.015 283.584 192.841 293.253C186.909 303.953 178.109 320.183 155.12 326.343C132.131 332.503 116.396 322.847 106.101 316.495C96.812 310.805 94.0021 309.591 88.3031 311.118C82.6042 312.645 80.7518 315.005 75.5779 324.674C69.7417 335.348 60.9426 351.578 37.9536 357.738C27.3284 360.585 16.3057 354.221 13.4587 343.596C10.6117 332.971 16.9757 321.948 27.6008 319.101C33.3964 317.548 35.1521 315.214 40.326 305.546C46.1622 294.871 54.9613 278.641 77.9504 272.481Z"
                        fill="white"/>
                    <path
                        d="M105.042 315.986C116.942 295.375 111.687 277.676 108.216 266.088C105.124 255.643 104.679 252.615 107.629 247.505C110.629 242.309 113.338 241.217 124.016 238.722C135.887 235.761 153.792 231.549 165.692 210.937C177.592 190.326 172.337 172.627 168.866 161.039C165.774 150.594 165.329 147.566 168.279 142.456C171.279 137.26 173.988 136.168 184.666 133.673C196.537 130.712 214.492 126.413 226.342 105.888C238.242 85.2771 232.987 67.5786 229.516 55.9906C226.388 45.4088 225.979 42.5169 228.979 37.3208C234.479 27.7945 231.185 15.5002 221.658 10.0002C212.132 4.50024 199.838 7.79447 194.338 17.3208C182.438 37.9322 187.693 55.6307 191.164 67.2187C194.255 77.6639 194.701 80.6923 191.751 85.8019C188.751 90.998 186.042 92.0899 175.364 94.5851C163.493 97.5463 145.538 101.845 133.688 122.37C121.838 142.894 127.043 160.68 130.514 172.268C133.605 182.713 134.051 185.741 131.101 190.851C128.101 196.047 125.392 197.139 114.714 199.634C102.843 202.595 84.8879 206.894 72.9879 227.505C61.0879 248.117 66.3426 265.815 69.8137 277.403C72.9053 287.848 73.3507 290.877 70.4007 295.986C67.4507 301.096 64.6919 302.274 54.0137 304.769C42.1926 307.644 24.2379 311.943 12.3379 332.554C6.83789 342.08 10.1321 354.375 19.6584 359.875C29.1847 365.375 41.4789 362.08 46.9789 352.554C49.9789 347.358 52.6877 346.266 63.366 343.771C75.187 340.896 93.1417 336.598 105.042 315.986Z"
                        fill="white"/>
                </clipPath>
            </svg>

            <div id='line_scene' style={{clipPath: `url(#mask`}} className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};
export default TimeLine;
