// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--hQ3Se";
export var how_to_get_time_container = "styles-module--how_to_get_time_container--OKBRJ";
export var how_to_get_time_item = "styles-module--how_to_get_time_item--PiKXB";
export var how_to_get_time_item_img_wrapper = "styles-module--how_to_get_time_item_img_wrapper--+BK9D";
export var how_to_get_time_items = "styles-module--how_to_get_time_items--9Ju-b";
export var how_to_use_img_wrapper = "styles-module--how_to_use_img_wrapper--XiMvJ";
export var p_mb = "styles-module--p_mb--MU3Yg";
export var scroll_to_explore = "styles-module--scroll_to_explore--lTMDB";
export var time_hero = "styles-module--time_hero--1-8yA";
export var time_hero_block = "styles-module--time_hero_block--94cmg";
export var time_hero_container = "styles-module--time_hero_container--wE+zk";
export var time_hero_img = "styles-module--time_hero_img--2T+vW";
export var time_hero_text = "styles-module--time_hero_text--I5fG7";
export var time_how_to_use_container = "styles-module--time_how_to_use_container--0bQdc";
export var time_how_to_use_img_wrapper = "styles-module--time_how_to_use_img_wrapper--1+U51";
export var time_how_to_use_item = "styles-module--time_how_to_use_item--DF70p";
export var time_how_to_use_text_box = "styles-module--time_how_to_use_text_box--SvKHm";
export var time_how_to_use_text_item = "styles-module--time_how_to_use_text_item--rqbIK";
export var time_how_to_use_text_item_active = "styles-module--time_how_to_use_text_item_active--dsspk";
export var time_info = "styles-module--time_info--c7zgZ";
export var time_info_item = "styles-module--time_info_item--crBFD";
export var time_info_item_img = "styles-module--time_info_item_img--TU-Dv";
export var time_info_item_text = "styles-module--time_info_item_text--a4Tqy";