import React from 'react';
import * as styles from "./styles.module.scss";
import CubeCircle from "../../ForInvestors/ForInvestorCircles/CubeCircle";
import LifeEllipse from "./LifeElipce";
import hourText from "../image/hourText.png"
import moon from "../image/moon.png"

const LifeTime = () => {
    return (
        <div className={styles.lifeTime_container}>
           <div className={styles.circle_block}>
               <CubeCircle/>
           </div>
            <div className={styles.ellipse_block}>
                <LifeEllipse/>
                <img className={styles.ellipse_block_text} src={hourText} alt="text"/>
            </div>
            <img className={styles.moon_block} src={moon} alt="moon"/>
        </div>
    );
};

export default LifeTime;