// extracted by mini-css-extract-plugin
export var bg_circle = "styles-module--bg_circle--nv9lY";
export var blueLike_block = "styles-module--blueLike_block--cGGfh";
export var blue_like_container = "styles-module--blue_like_container--sMMIU";
export var circle = "styles-module--circle--6GXaX";
export var circle_container = "styles-module--circle_container--iY+rR";
export var clock_arrow = "styles-module--clock_arrow--7DFua";
export var darkLike_block = "styles-module--darkLike_block--gsy9T";
export var dark_like_container = "styles-module--dark_like_container--ubVC1";
export var dashed = "styles-module--dashed--8piQm";
export var line_container = "styles-module--line_container--DVUUv";
export var polygon = "styles-module--polygon--MfchA";
export var polygon_hand = "styles-module--polygon_hand--ItEr1";
export var timeCircle_block = "styles-module--timeCircle_block--YylB1";
export var timeHeader_container = "styles-module--timeHeader_container--MHFY4";
export var timeLine_block = "styles-module--timeLine_block--tRRM1";