// extracted by mini-css-extract-plugin
export var aye = "styles-module--aye--HKk4k";
export var container = "styles-module--container--jqnpZ";
export var copyright_block = "styles-module--copyright_block--tCifI";
export var email_block = "styles-module--email_block--4a4QS";
export var email_blocks = "styles-module--email_blocks--TwiMM";
export var glyph = "styles-module--glyph--Hw7DA";
export var linkIcons_block = "styles-module--linkIcons_block--QC876";
export var links_block = "styles-module--links_block--fe9eJ";
export var privacy_block = "styles-module--privacy_block--TOqJe";
export var row = "styles-module--row--kuhao";