// extracted by mini-css-extract-plugin
export var CubeCircle_block = "styles-module--CubeCircle_block--+Dqmr";
export var CubeCircle_container = "styles-module--CubeCircle_container--Os8S+";
export var NCircle_block = "styles-module--NCircle_block--oz5Qb";
export var NCircle_container = "styles-module--NCircle_container--soWmK";
export var UCircle_block = "styles-module--UCircle_block--dgsNb";
export var UCircle_container = "styles-module--UCircle_container--vOVqp";
export var cube_img = "styles-module--cube_img--f9bJx";
export var dashed = "styles-module--dashed--NGizG";
export var forInvestors_circles_container = "styles-module--forInvestors_circles_container--VSiH6";
export var polygon = "styles-module--polygon--UjYTJ";