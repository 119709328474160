import * as React from "react"
 //styles
import * as styles from "./styles.module.scss";
 //assets
import SocialLinks from "../UI/SocialLinks";
import EmailBlock from "../UI/EmailBlock";

const Footer = () => (
    <footer>
        <div className={styles.container}>
           <div className={styles.row}>
               <div className={styles.linkIcons_block}>
                   <a className={styles.glyph} href="https://newlayer.ar-generation.com" target='_blank'></a>
                   <a className={styles.aye} href="https://ar-generation.com" target='_blank'></a>
               </div>
               <div className={styles.email_blocks}>
                   <EmailBlock/>
               </div>
               <div className={styles.links_block}>
                    <SocialLinks/>
               </div>
               <div className={styles.copyright_block}>
                   <p>AR Generation Sp. z o. o. © 2021 <a href="https://ar-generation.com/gdpr.html" target="_blank">GDPR General information</a></p>
               </div>
               <div className={styles.privacy_block}>
                   <a href="https://ar-generation.com/policy.html" target='_blank'>Privacy policy</a>
                   <a href="https://ar-generation.com/terms.html" target='_blank'>Terms of use</a>
               </div>
           </div>
        </div>
    </footer>
)


export default Footer
