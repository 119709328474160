import React, {useEffect, useState} from 'react';
import * as styles from "./styles.module.scss";
import arrow from "../../images/arrow.png";
import {scrollToTop} from "../../helpers/ScrollTop";

const handleClick = () => {
    scrollToTop(true)
}

const ScrollTopBtn = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [])

    return <div>
        {scrollPosition >= 500 ?
            <button onClick={handleClick} className={styles.scrollBtn}><img src={arrow} alt="arrow"/></button> : ''
        }
    </div>;
};

export default ScrollTopBtn;