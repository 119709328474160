import React, {useEffect} from 'react';
import Parallax from "parallax-js";
import blueLike from "../img/blueLike.png"
import darkLike from "../img/darkLike.png"
import dashed from "../../dashedImg/whiteBg.png"
import * as styles from "./styles.module.scss";


export const BlueLike = () => {
    useEffect(() => {
        const blue_like_scene = document.getElementById("blue_like_scene");
        const blue_like_hand_scene = document.getElementById("blue_like_hand_scene");
        new Parallax(blue_like_hand_scene)
        new Parallax(blue_like_scene);
    });

    return (
        <div className={styles.blue_like_container}>
            <img src={blueLike} alt=""/>
            <div id='blue_like_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
            <div id='blue_like_hand_scene' className={styles.polygon_hand}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};

export const DarkLike = () => {
    useEffect(() => {
        const dark_like_scene = document.getElementById("dark_like_scene");
        const dark_like_hand_scene = document.getElementById("dark_like_hand_scene");
        new Parallax(dark_like_hand_scene)
        new Parallax(dark_like_scene);
    });

    return (
        <div className={styles.dark_like_container}>
            <img src={darkLike} alt=""/>
            <div id='dark_like_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
            <div id='dark_like_hand_scene' className={styles.polygon_hand}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
}
