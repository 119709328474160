import React, {useEffect} from 'react';
import Parallax from "parallax-js";
import circle from "../img/circle.png"
import clocksArrow from "../img/clockArrows.png"
import dashed from "../../dashedImg/darkBlueBg.png";
import * as styles from "./styles.module.scss";
import "./style.css"

const TimeCircle = () => {

    useEffect(() => {
        const line_circle = document.getElementById("line_circle");
        new Parallax(line_circle);
    });

    return (
        <div className={styles.circle_container}>
            <svg width="399" height="399">
                <clipPath id="mask_circle">
                    <path d="M216.488 95.1805C246.58 90.9093 277.25 96.3516 304.036 110.716C330.821 125.079 352.324 147.616 365.417 175.045C378.509 202.474 382.508 233.366 376.831 263.224C371.154 293.083 356.097 320.352 333.852 341.062C311.607 361.773 283.333 374.845 253.145 378.376L256.409 406.276L253.145 378.376C222.957 381.908 192.43 375.715 166.005 360.698C139.58 345.681 118.636 322.625 106.221 294.882C93.8049 267.14 90.5654 236.16 96.9733 206.449C100.244 191.284 90.6019 176.339 75.437 173.069C60.2721 169.798 45.3271 179.44 42.0564 194.605C33.1273 236.005 37.6414 279.174 54.9419 317.831C72.2424 356.488 101.426 388.617 138.248 409.542C175.07 430.467 217.608 439.097 259.673 434.176C301.738 429.254 341.135 411.039 372.133 382.181C403.131 353.322 424.111 315.325 432.022 273.718C439.933 232.111 434.361 189.066 416.117 150.845C397.873 112.624 367.91 81.2208 330.586 61.2055C293.262 41.1902 250.525 33.6067 208.593 39.5583C193.234 41.7383 182.549 55.9571 184.729 71.3167C186.91 86.6764 201.128 97.3605 216.488 95.1805Z"/>
                </clipPath>
            </svg>

                <img src={clocksArrow} className={styles.clock_arrow} alt="arrow"/>

            <img  className={styles.circle} src={circle} alt=""/>
            <div id='line_circle' style={{clipPath: `url(#mask_circle`}} className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};
export default TimeCircle;
