import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import ellipse from "../image/elipse.png"
import dashed from "../../dashedImg/whiteBg.png"

const LifeEllipse = () => {
    useEffect(() => {
        const ellipse_scene = document.getElementById("ellipse_scene");
        new Parallax(ellipse_scene);
    }, []);

    return (
        <div className={styles.ellipse_container}>
            <img src={ellipse} alt=""/>
            <div id='ellipse_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};

export default LifeEllipse;



