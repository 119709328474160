import React, {useEffect} from "react";
import RegisterImg from '../../images/Time2/Registr.png'
import CalendarImg from '../../images/Time2/Calendar.png'
import BuyImg from '../../images/Time2/Buy.png'
import LikeImg from '../../images/Time2/Like.png'
import TimeGif from '../../assets/gifs/PlanetGif.gif'
import * as styles from './styles.module.scss'
import Footer from "../../components/Footer/Footer";
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";
import LifeTime from "../../assets/animateImg/Time/LifeTime/LifeTime";
import TimeMain from "../../assets/animateImg/Time/TimeMain/TimeMain";

const Time = () => {

    return (
        <div>
            <ScrollTopBtn/>
            <section className={styles.time_hero_container}>
                <div className={styles.time_hero}>
                    <FadeInSection>
                        <div className={styles.time_hero_block}>
                            <div className={styles.time_hero_text}>
                                <h1>
                                    IT’S TIME
                                </h1>
                                <p>Have you ever felt that you don't have enough time? <br/>So now you can buy it!</p>
                            </div>
                            <div className={styles.time_hero_img}>
                                <TimeMain/>
                            </div>
                        </div>
                    </FadeInSection>
                    <div className={styles.scroll_to_explore}>
                        <FadeInSection>
                            <h5>SCROLL TO EXPLORE</h5>
                            <p className={styles.arrow}></p>
                        </FadeInSection>
                    </div>
                </div>
            </section>
            <section className={styles.time_info}>
                <FadeInSection>
                    <div className={styles.time_info_item}>
                        <div className={styles.time_info_item_text}>
                            <h3>a lifetime</h3>
                            <p className={styles.p_mb}>Every 3D object placed in the Unisphere has a lifetime. It is
                                counted in hours and called
                                Time.
                            </p>
                            <p>One Time is equal to 1 hour.</p>
                        </div>
                        <div className={styles.time_info_item_img}>
                            <LifeTime/>
                        </div>
                    </div>
                </FadeInSection>
            </section>
            <section className={styles.time_how_to_use_container}>
                <FadeInSection><h2>How to use the Time?</h2></FadeInSection>
                <FadeInSection>
                    <div className={styles.time_how_to_use_item}>
                        <div className={styles.how_to_use_img_wrapper}>
                            <img src={TimeGif} alt=""/>
                        </div>
                    </div>
                </FadeInSection>
            </section>
            <FadeInSection>
                <section className={styles.how_to_get_time_container}>
                    <h2>How to get the Time?</h2>
                    <div className={styles.how_to_get_time_items}>
                        <div className={styles.how_to_get_time_item}>
                            <div className={styles.how_to_get_time_item_img_wrapper}>
                                <img src={RegisterImg} alt=""/>
                            </div>
                            <p>Receive days with registration in the Unisphere.</p>
                        </div>
                        <div className={styles.how_to_get_time_item}>
                            <div className={styles.how_to_get_time_item_img_wrapper}>
                                <img src={CalendarImg} alt=""/>
                            </div>
                            <p>Use the New Layer and get time for every usage day.</p>
                        </div>
                        <div className={styles.how_to_get_time_item}>
                            <div className={styles.how_to_get_time_item_img_wrapper}>
                                <img src={BuyImg} alt=""/>
                            </div>
                            <p>You can also buy an extra time.</p>
                        </div>
                        <div className={styles.how_to_get_time_item}>
                            <div className={styles.how_to_get_time_item_img_wrapper}>
                                <img src={LikeImg} alt=""/>
                            </div>
                            <p>Get time rewards for positive reactions to the objects you placed.</p>
                        </div>
                    </div>
                </section>
            </FadeInSection>
            <Footer/>
        </div>
    )
}
export default Time
